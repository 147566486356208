import { ref, computed } from 'vue';
<template lang="pug">
.container.mb-32
  .w-full.rounded-3xl.bg-gradient-to-b.from-blue-500.to-blue-600#observableContainer
    .wrapper-analitycs
      XyzTransition(xyz="fade delay-1")
        .item(v-if="newUsers > 0")
          .font-normal.text-7xl.tracking-tighter {{ totalUsers }}
          .font-normal.text-lg Nuevos Usuarios
      XyzTransition(xyz="fade delay-1")
        .item(v-if="newCVs > 0")
          .font-normal.text-7xl.tracking-tighter {{ totalCvs }}
          .font-normal.text-lg CV's Creados Hoy
      XyzTransition(xyz="fade delay-1")
        .item(v-if="clients > 0")
          .font-normal.text-7xl.tracking-tighter +{{ clients }}k
          .font-normal.text-lg Clientes Satisfechos
  .flex.mx-auto.mt-20
    BaseButton.bg-green-400.inline-block.mx-auto.text-white.font-normal.text-3xl.shadow-brandGreen Crear mi CV
</template>
<script>
export default {
  name: "sectionAnalitycs",
  data: () => ({
    observer: null,
    newUsers: 0,
    newCVs: 0,
    clients: 0

  }),
  mounted() {
    const callback = (entries) => this.isIntersecting(entries[0].isIntersecting);
    this.observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: "0px",
      threshold: .1
    })
    const observerHtmlElement = document.getElementById('observableContainer');
    this.observer.observe(observerHtmlElement)
  },
  methods: {
    isIntersecting(isIntersecting) {
      if (isIntersecting) {
        this.observer.disconnect();
        this.initIntervalCV();
        this.initIntervalUsers();
        this.initIntervalClients();
      }
    },
    initIntervalCV() {
      let intervalTime = 15;
      let cuantityToSum = 50
      const interval = setInterval(() => {
        this.newCVs += cuantityToSum;
        if (this.newCVs > 2500) cuantityToSum = 20;
        if (this.newCVs > 3000) cuantityToSum = 10;
        if (this.newCVs > 3100) cuantityToSum = 1;
        if (this.newCVs > 3196) clearInterval(interval)
      }, intervalTime)
    },
    initIntervalUsers() {
      let intervalTime = 15;
      let cuantityToSum = 50
      const interval = setInterval(() => {
        this.newUsers += cuantityToSum;
        if (this.newUsers > 1500) cuantityToSum = 20;
        if (this.newUsers > 2000) cuantityToSum = 10;
        if (this.newUsers > 2100) cuantityToSum = 1;
        if (this.newUsers > 2225) clearInterval(interval)
      }, intervalTime)
    },
    initIntervalClients() {
      let intervalTime = 60;
      let cuantityToSum = 10;
      const interval = setInterval(() => {
        this.clients += cuantityToSum;
        if (this.clients > 499) clearInterval(interval)
      }, intervalTime)
    }
  },
  computed: {
    totalUsers() {
      return this.newUsers.toLocaleString('es-MX').replace(',', '.')
    },
    totalCvs() {
      return this.newCVs.toLocaleString('es-MX').replace(',', '.')
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper-analitycs {
  @apply grid grid-cols-1 divide-y divide-blue-500 p-5 text-white sm:grid-cols-3 sm:divide-x sm:divide-y-0;

  .item {
    @apply flex items-center justify-center text-center flex-col py-4;
  }
}
</style>